import styled, { createGlobalStyle, css } from "styled-components";
import { normalize } from "styled-normalize";
// import { fontFamily } from "../../theme";
import "./layout.css";

const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${props =>
    props.row &&
    css`
      flex-direction: row;
    `}

  ${props =>
    props.column &&
    css`
      flex-direction: column;
    `}

  ${props =>
    props.spaceBetween &&
    css`
      justify-content: space-between;
    `}

  ${props =>
    props.flexEnd &&
    css`
      justify-content: flex-end;
    `}

  ${props =>
    props.alignTop &&
    css`
      justify-content: flex-start;
    `}

    ${props =>
    props.alignCenter &&
    css`
      justify-content: center;
    `}


  ${props =>
    props.alignLeft &&
    css`
      align-items: flex-start;
    `}

  ${props =>
    props.noHeight &&
    css`
      height: 0;
    `}
`;

const GlobalStyle = createGlobalStyle`
    ${normalize};
`;
export { Flex };
export default GlobalStyle;
