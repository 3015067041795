import React from "react";
import GlobalStyle from "./src/styles/global-styles";

// const loadableReady = require("@loadable/component").loadableReady;

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyle />
    {element}
  </>
);

// export const replaceHydrateFunction = () => {
//   return (element, container, callback) => {
//     loadableReady(() => {
//       ReactDOM.render(element, container, callback);
//     });
//   };
// };
