// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js" */),
  "component---src-pages-contact-me-js": () => import("./../../../src/pages/contact-me.js" /* webpackChunkName: "component---src-pages-contact-me-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-my-skills-js": () => import("./../../../src/pages/my-skills.js" /* webpackChunkName: "component---src-pages-my-skills-js" */),
  "component---src-pages-projects-condexo-pay-js": () => import("./../../../src/pages/projects/condexo-pay.js" /* webpackChunkName: "component---src-pages-projects-condexo-pay-js" */),
  "component---src-pages-projects-condexo-property-management-js": () => import("./../../../src/pages/projects/condexo-property-management.js" /* webpackChunkName: "component---src-pages-projects-condexo-property-management-js" */),
  "component---src-pages-projects-harmindra-international-js": () => import("./../../../src/pages/projects/harmindra-international.js" /* webpackChunkName: "component---src-pages-projects-harmindra-international-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

